.shoppyfit {
    display: none;
}


/******************************
 * Global colors and shadows
 */

:root {
    --base-font-size: 16px;
    --font: "Roboto","Helvetica","Arial",sans-serif;
    --shoppyfit-keyboard-focus: #1e90ff;
    --shoppyfit-background: #fff;
    --shoppyfit-background-2: #fff;
    --shoppyfit-border: #d2d2d2;
    --shoppyfit-text: #bc0080;
    --shoppyfit-text-2: #000;
    --shoppyfit-close-background: #e5e5e5;
    --shoppyfit-close-text: #777;
    --shoppyfit-overlay-background: rgba(20 20 20 / 25%);
    --shoppyfit-modal-btn-background: #f5f5f5;
    --shoppyfit-modal-btn-background-2: #fff;
    --shoppyfit-modal-btn-border: #bc0080;
    --shoppyfit-modal-btn-border-2: #d2d2d2;
    --shoppyfit-modal-btn-text: #333;
    --shoppyfit-modal-btn-text-2: #161616;
    --shoppyfit-modal-btn-sec-text: #4f4f4f;
    --shoppyfit-shadow-button: rgba(0 0 0 / 30%) 2px 5px 18px -1px, rgba(0 0 0 / 25%) 2px 2px 10px -3px;
    --shoppyfit-shadow-button-hover: #bc0080;
    --shoppyfit-shadow-button-active: rgba(0 0 0 / 50%) 2px 5px 18px -1px, rgba(0 0 0 / 45%) 2px 2px 10px -3px;
    --shoppyfit-shadow-list: rgba(0 0 0 / 20%) 2px 5px 18px -1px, rgba(0 0 0 / 40%) 2px 2px 10px -3px;
    --shoppyfit-shadow-list-modal: rgba(0 0 0 / 60%) 3px 6px 40px -5px, rgba(0 0 0 / 60%) 3px 3px 15px -4px;
    --shoppyfit-shadow-modal: rgba(0 0 0 / 0%) 0 0 0 0, rgba(0 0 0 / 0%) 0 0 0 0, rgba(0 0 0 / 60%) 3px 6px 40px -5px, rgba(0 0 0 / 60%) 3px 3px 15px -4px;
    --shoppyfit-shadow-modal-button: rgba(0 0 0 / 30%) 2px 3px 10px -1px, rgba(0 0 0 / 25%) 2px 2px 8px -3px;
    --shoppyfit-shadow-modal-button-primary: rgba(0 0 0 / 40%) 2px 3px 10px -1px, rgba(0 0 0 / 35%) 2px 2px 8px -3px;
    --shoppyfit-shadow-modal-button-hover: rgba(0 0 0 / 45%) 5px 8px 20px -1px, rgba(0 0 0 / 30%) 2px 4px 10px -3px;
    --bg-cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23777' width='16' height='16' viewBox='0 0 122.878 122.88'%3E%3Cpath d='M1.426 8.313a4.87 4.87 0 0 1 0-6.886 4.87 4.87 0 0 1 6.886 0l53.127 53.127 53.127-53.127a4.87 4.87 0 0 1 6.887 0 4.87 4.87 0 0 1 0 6.886L68.324 61.439l53.128 53.128a4.87 4.87 0 0 1-6.887 6.886L61.438 68.326 8.312 121.453a4.87 4.87 0 0 1-6.886 0 4.87 4.87 0 0 1 0-6.886l53.127-53.128L1.426 8.313h0z'/%3E%3C/svg%3E") 16 16, crosshair;
    --icon-ms365-color: #ea3e23;
    --icon-yahoo-color: #5f01d1;
    --icon-filter: none;
}


/* Dark mode */

.shoppyfit-button-wrapper.shoppyfit-dark,
body.shoppyfit-dark .shoppyfit-button-wrapper.shoppyfit-bodyScheme,
.shoppyfit-list.shoppyfit-dark,
body.shoppyfit-dark .shoppyfit-list.shoppyfit-bodyScheme,
.shoppyfit-modal-box.shoppyfit-dark,
body.shoppyfit-dark .shoppyfit-modal-box.shoppyfit-bodyScheme,
#shoppyfit-bgoverlay.shoppyfit-dark,
body.shoppyfit-dark #shoppyfit-bgoverlay.shoppyfit-bodyScheme {
    --shoppyfit-background: #2e2e2e;
    --shoppyfit-background-2: #373737;
    --shoppyfit-border: #4d4d4d;
    --shoppyfit-text: #dedede;
    --shoppyfit-text-2: #f1f1f1;
    --shoppyfit-close-background: #282828;
    --shoppyfit-overlay-background: rgba(20 20 20 / 60%);
    --shoppyfit-shadow-button: rgba(255 255 255 / 5%) -12px -5px 20px -8px, rgba(255 255 255 / 6%) -7px -5px 15px -3px, rgba(0 0 0 / 50%) 2px 5px 18px -1px, rgba(0 0 0 / 40%) 3px 3px 20px -3px;
    --shoppyfit-shadow-button-hover: rgba(255 255 255 / 6%) -12px -5px 23px -8px, rgba(255 255 255 / 7%) -7px -5px 18px -3px, rgba(0 0 0 / 60%) 2px 5px 19px -1px, rgba(0 0 0 / 50%) 3px 3px 22px -3px;
    --shoppyfit-shadow-button-active: rgba(255 255 255 / 7%) -12px -5px 23px -8px, rgba(255 255 255 / 8%) -7px -5px 18px -3px, rgba(0 0 0 / 70%) 2px 5px 19px -1px, rgba(0 0 0 / 60%) 3px 3px 22px -3px;
    --shoppyfit-shadow-list: rgba(255 255 255 / 5%) -12px -5px 20px -8px, rgba(255 255 255 / 6%) -7px -5px 15px -3px, rgba(0 0 0 / 50%) 2px 5px 18px -1px, rgba(0 0 0 / 40%) 3px 3px 20px -3px;
    --shoppyfit-shadow-list-modal: rgba(255 255 255 / 8%) -12px -5px 30px -8px, rgba(255 255 255 / 8%) -7px -5px 15px -3px, rgba(0 0 0 / 60%) 4px 6px 50px -4px, rgba(0 0 0 / 90%) 8px 12px 40px -2px;
    --shoppyfit-shadow-modal: rgba(255 255 255 / 8%) -12px -5px 30px -8px, rgba(255 255 255 / 8%) -7px -5px 15px -3px, rgba(0 0 0 / 60%) 4px 6px 50px -4px, rgba(0 0 0 / 90%) 8px 12px 40px -2px;
    --icon-ms365-color: #ea3e23;
    --icon-yahoo-color: #bebebe;
    --icon-filter: grayscale(.2);
}


/******************************
 * The triggering button
 */

.shoppyfit-button-wrapper {
    display: inline-block;
    font-size: var(--base-font-size);
    padding: 5px;
    position: relative;
}

.shoppyfit-button {
    align-items: center;
    background-color: var(--shoppyfit-background);
    border: 1px solid var(--shoppyfit-border);
    border-radius: 8px;
    box-shadow: var(--shoppyfit-shadow-button);
    color: var(--shoppyfit-text-2);
    cursor: pointer;
    display: flex;
    font-family: var(--font);
    font-size: var(--base-font-size);
    font-weight: 400;
    justify-content: center;
    line-height: 1.5em;
    margin: .13em;
    max-width: 350px;
    min-width: 160px;
    padding: .65em 1em;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    width: auto;
    z-index: 1;
}

.shoppyfit-rtl .shoppyfit-button {
    direction: rtl;
    text-align: right;
}

.shoppyfit-button:focus,
.shoppyfit-button:hover {
    background-color: var(--shoppyfit-background-2);
    box-shadow: var(--shoppyfit-shadow-button-hover);
}

.shoppyfit-button:focus-visible {
    outline: 2px solid var(--shoppyfit-keyboard-focus);
}

.shoppyfit-button.shoppyfit-active:not(.shoppyfit-modal-style,
.shoppyfit-dropoverlay),
.shoppyfit-button.shoppyfit-single:focus,
.shoppyfit-button.shoppyfit-single:hover {
    background-color: var(--shoppyfit-background-2);
    box-shadow: var(--shoppyfit-shadow-button-active);
    margin: 0;
    padding: .78em 1.13em;
    z-index: 15000000;
}

.shoppyfit-button.shoppyfit-active.shoppyfit-dropoverlay {
    z-index: 14000090;
}


/******************************
 * Button Icon (also base for list icons) & Text
 */

.shoppyfit-icon {
    height: var(--base-font-size);
    margin-bottom: .3em;
    margin-right: .8em;
}

.shoppyfit-rtl .shoppyfit-icon {
    margin-right: 0;
    margin-left: .8em;
}

.shoppyfit-icon svg {
    height: 100%;
    fill: currentcolor;
    width: auto;
}

.shoppyfit-text {
    overflow-wrap: anywhere;
}


/******************************
 * Options List
 */

.shoppyfit-dropdown-anchor {
    bottom: 4px;
    height: 1px;
    width: 100%;
    opacity: 0;
    position: absolute;
}

.shoppyfit-list-wrapper {
    box-sizing: border-box;
    padding: 0 4px;
    position: absolute;
    z-index: 14000090;
}

.shoppyfit-list-wrapper.shoppyfit-dropoverlay {
    z-index: 15000000;
}

.shoppyfit-list {
    border-radius: 0 0 6px 6px;
    box-sizing: border-box;
    box-shadow: var(--shoppyfit-shadow-list);
    color: var(--shoppyfit-text);
    display: block;
    font-family: var(--font);
    font-size: var(--base-font-size);
    max-width: 100%;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    width: 100%;
}

.shoppyfit-iframe {
    align-items: center;
    background-color: var(--shoppyfit-background);
    border: 1px solid var(--shoppyfit-border);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: var(--base-font-size);
    top: 50%;
    line-height: 1.75em;
    padding: .8em;
    text-align: left;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
}

.shoppyfit-rtl .shoppyfit-iframe {
    direction: rtl;
    text-align: right;
}

.shoppyfit-iframe:hover {
    background-color: var(--shoppyfit-background-2);
    color: var(--shoppyfit-text-2);
}

.shoppyfit-iframe:focus-visible {
    background-color: var(--shoppyfit-background-2);
    color: var(--shoppyfit-keyboard-focus);
    font-size: .95em;
    font-weight: 600;
    outline: none;
}

.shoppyfit-iframe:last-child {
    border-radius: 0 0 6px 6px;
}

.shoppyfit-list .shoppyfit-iframe:not(:first-child) {
    border-top-style: none;
}

.shoppyfit-dropup .shoppyfit-iframe:last-child {
    border-radius: 0;
    padding-bottom: 1.25em;
}

.shoppyfit-dropup .shoppyfit-iframe:first-child,
.shoppyfit-dropoverlay .shoppyfit-list .shoppyfit-iframe:first-child,
.shoppyfit-list.shoppyfit-modal .shoppyfit-iframe:first-child {
    border-radius: 6px 6px 0 0;
}

.shoppyfit-dropoverlay .shoppyfit-list .shoppyfit-iframe:only-child,
.shoppyfit-list.shoppyfit-modal .shoppyfit-iframe:only-child {
    border-radius: 6px;
}

.shoppyfit-list.shoppyfit-generated-button:not(.shoppyfit-modal) .shoppyfit-iframe:first-child {
    padding-top: 1.25em;
}

.shoppyfit-dropup .shoppyfit-list.shoppyfit-generated-button:not(.shoppyfit-modal) .shoppyfit-iframe:first-child,
.shoppyfit-dropoverlay .shoppyfit-list.shoppyfit-generated-button:not(.shoppyfit-modal) .shoppyfit-iframe:first-child {
    padding-top: .8em;
}

.shoppyfit-dropoverlay .shoppyfit-list,
.shoppyfit-list.shoppyfit-modal {
    border-radius: 6px;
}

.shoppyfit-list.shoppyfit-modal {
    box-shadow: var(--shoppyfit-shadow-list-modal);
}

.shoppyfit-iframe .shoppyfit-icon {
    margin-bottom: .5em;
    margin-right: .6em;
    width: 1.125em;
}

.shoppyfit-rtl .shoppyfit-iframe .shoppyfit-icon {
    margin-right: 0;
    margin-left: .6em;
}

.shoppyfit-iframe-close {
    background-color: var(--shoppyfit-close-background);
}

.shoppyfit-iframe.shoppyfit-iframe-close {
    color: var(--shoppyfit-close-text);
}

.shoppyfit-iframe-close svg {
    fill: currentcolor;
}


/******************************
 * Modal
 */


/* we disable scrolling only on small screens - only there, the case is relevant and at the same time, they have no scrollbars and therefore no "jumping" */

@media only screen and (max-width: 575px) {
    .shoppyfit-modal-no-scroll {
        overflow-y: hidden;
    }
}

.shoppyfit-modal {
    display: block;
    min-width: 25em;
    min-height: 100%;
    width: auto;
    right: 0;
    top:0;
    position: fixed;
    z-index: 14000090;
}

.shoppyfit-modal-box {
    background-color: var(--shoppyfit-background-2);
    box-shadow: var(--shoppyfit-shadow-modal);
    color: var(--shoppyfit-text-2);
    cursor: default;
    border-radius: 6px;
    border: 1px solid var(--shoppyfit-border);
    box-sizing: border-box;
    font-family: var(--font);
    font-size: var(--base-font-size);
    line-height: 1.5em;
    padding: 1.25em 1.5em;
    text-align: left;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    max-width: 32em;
    -webkit-tap-highlight-color: transparent;
}

.shoppyfit-modal-box.shoppyfit-rtl {
    text-align: right;
    direction: rtl;
}

.shoppyfit-modal-close {
    cursor: pointer;
    display: inline-block;
    height: 1.125em;
    position: absolute;
    right: 1.25em;
}

.shoppyfit-rtl .shoppyfit-modal-close {
    left: 1.25em;
    right: auto;
}

.shoppyfit-modal-close svg {
    height: 100%;
    fill: var(--shoppyfit-close-text);
    width: auto;
}

.shoppyfit-modal-close:hover {
    opacity: .6;
}

.shoppyfit-modal-close:focus-visible {
    outline: 2px solid var(--shoppyfit-keyboard-focus);
}

.shoppyfit-modal-headline {
    align-items: center;
    display: flex;
    font-size: 1.3em;
    font-weight: 600;
    line-height: 1.5em;
    margin: 1em .9em 1.25em;
    text-transform: uppercase;
}

.shoppyfit-rtl .shoppyfit-modal-headline {
    direction: rtl;
    text-align: right;
}

.shoppyfit-modal-headline-icon {
    height: 1.625em;
    margin-top: .125em;
    margin-right: .9em;
}

.shoppyfit-rtl .shoppyfit-modal-headline-icon {
    margin-right: 0;
    margin-left: .9em;
}

.shoppyfit-modal-headline-icon svg {
    height: 100%;
    fill: currentcolor;
    width: auto;
}

.shoppyfit-modal-content {
    margin: 30px 15px;
}

.shoppyfit-modal-buttons {
    padding: .9em 0;
    text-align: center;
    width: 100%;
}

button.shoppyfit-modal-btn,
a.shoppyfit-modal-btn {
    background-color: var(--shoppyfit-modal-btn-background);
    border: 1px solid var(--shoppyfit-modal-btn-border);
    border-radius: 6px;
    box-shadow: var(--shoppyfit-shadow-modal-button);
    color: var(--shoppyfit-modal-btn-sec-text);
    cursor: pointer;
    display: inline-block;
    font-family: var(--font);
    font-size: var(--base-font-size);
    font-weight: 600;
    line-height: 1.5em;
    margin: .625em;
    padding: .625em 1.25em;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
}

button.shoppyfit-modal-btn.shoppyfit-modal-btn-primary,
a.shoppyfit-modal-btn.shoppyfit-modal-btn-primary {
    border: 1px solid var(--shoppyfit-modal-btn-border-2);
    box-shadow: var(--shoppyfit-shadow-modal-button-primary);
    color: var(--shoppyfit-modal-btn-text);
}

button.shoppyfit-modal-btn:hover,
a.shoppyfit-modal-btn:hover {
    background-color: var(--shoppyfit-modal-btn-background-2);
    box-shadow: var(--shoppyfit-shadow-modal-button-hover);
    color: var(--shoppyfit-modal-btn-text-2);
    text-decoration: none;
}

button.shoppyfit-modal-btn:focus-visible,
a.shoppyfit-modal-btn:focus-visible {
    background-color: var(--shoppyfit-modal-btn-background-2);
    outline: 2px solid var(--shoppyfit-keyboard-focus);
}


/******************************
 * Background Overlay
 */

#shoppyfit-bgoverlay {
    /* animation: shoppyfit-bgoverlay-animate 0.1s ease 0s 1 normal forwards;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    background-color: var(--shoppyfit-overlay-background);
    box-sizing: border-box; */
    display: flex;
    height: calc(100vh + 100px);
    right: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    overflow-y: auto;
    padding: 30px 20px 130px;
    position: fixed;
    transition: 0.5s;
    width: 100vw;
    z-index: 14000000;
}

#shoppyfit-bgoverlay.shoppyfit-animate-bg {
    animation: shoppyfit-bgoverlay-animate 0.3s ease 0s 1 normal forwards;
}

#shoppyfit-bgoverlay.shoppyfit-no-bg {
    animation: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    opacity: 0;
}

@keyframes shoppyfit-bgoverlay-animate {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#shoppyfit-bgoverlay.shoppyfit-click:hover {
    cursor: var(--bg-cursor);
}


/******************************
 * Icon specifics
 */

.shoppyfit-icon .shoppyfit-icon-apple svg {
    fill: currentcolor;
}

.shoppyfit-icon .shoppyfit-icon-ical svg {
    fill: currentcolor;
}

.shoppyfit-icon .shoppyfit-icon-ms365 svg {
    fill: var(--icon-ms365-color);
}

.shoppyfit-icon .shoppyfit-icon-yahoo svg {
    fill: var(--icon-yahoo-color);
}

.shoppyfit-icon .shoppyfit-icon-google svg,
.shoppyfit-icon .shoppyfit-icon-msteams svg,
.shoppyfit-icon .shoppyfit-icon-outlookcom svg {
    filter: var(--icon-filter);
}

